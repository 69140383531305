<template>
    <div class="modal fade" :id="modalId" tabindex="-1" :aria-labelledby="modalId + 'Label'" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"> {{ title }} </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div style="text-align:center">
                        <div class="container col-md-10 d-grid gap-2">
                            
                            <!-- <div class="form-floating form-v-select mb-2">

                                <v-select v-if="this.listPositionUnits"
                                    :inputId="'inputParent'"
                                    :options="listPositionUnits"
                                    :reduce="col => col.id"
                                    :clearable = "true"
                                    label="name" v-model="positionUnit.parent_id"
                                    class="form-control" id="inputOrders"



                                    >

                                    <template slot="option" slot-scope="option">
                                       <span><strong>({{option.code}})</strong> <span>{{option.name}}</span></span>
                                    </template>

                                    <template slot="selected-option" slot-scope="option">
                                       <span>({{option.code}}) <span>{{option.name}}</span></span>
                                       
                                    </template>

                                </v-select>
                                <label for="floatingInput">Nadgrupa:</label>
                            </div> -->
                            <div class="form-floating">
                                <input v-model="positionUnit.code" class="form-control" type="text" placeholder="Šifra:" aria-label="default input example" required>
                                <label for="floatingInput">Šifra:</label>
                                <div class="invalid-feedback">
                                    Šifra je obavezno polje.
                                </div>
                            </div>
                            <div class="form-floating">
                                <input v-model="positionUnit.name" class="form-control" type="text" placeholder="Naziv:" aria-label="default input example" required>
                                <label for="floatingInput">Naziv:</label>
                                <div class="invalid-feedback">
                                    Naziv je obavezno polje.
                                </div>
                            </div>
                            <div class="form-floating">
                                <input v-model="positionUnit.norm_unit" class="form-control" type="text" placeholder="Merna jedinica:" aria-label="default input example" required>
                                <label for="floatingInput">Merna jedinica:</label>
                                <div class="invalid-feedback">
                                    Merna jedinica je obavezno polje.
                                </div>
                            </div>
                            <div class="form-floating">
                                <input v-model="positionUnit.norm_quantity" class="form-control" type="number" placeholder="Količina:" aria-label="default input example" required>
                                <label for="floatingInput">Količina:</label>
                                <div class="invalid-feedback">
                                    Količina je obavezno polje.
                                </div>            
                            </div>
                            <div class="form-floating">
                                <input v-model="positionUnit.minutes_per_unit" class="form-control" type="number" placeholder="Minuta po jedinici:" aria-label="default input example" required>
                                <label for="floatingInput">Minuta po jedinici:</label>
                                <div class="invalid-feedback">
                                    Minuta po jedinici je obavezno polje.
                                </div>         
                            </div>
                            <div class="form-floating">
                                <input v-model="positionUnit.description" class="form-control" type="text" placeholder="Opis:" aria-label="default input example">
                                <label for="floatingInput">Opis</label>
                            </div>
                            <div class="form-floating">
                                <input v-model="positionUnit.video_link" class="form-control" type="text" placeholder="Video:" aria-label="default input example">
                                <label for="floatingInput">Video:</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"> Zatvori </button>
                    <button @click="$emit('confirmed')" type="button" class="btn btn-primary"> Potvrdi </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {

    props: {
        modalId: String,
        title: String,
        positionUnit: Object,
        listPositionUnits: Array,
    },

}
</script>

<style scoped>
p {
    font-size: 140%;
}
.v-select.form-control{
    height: 4.5em;
}
</style>