import axios from "axios";
import config from "@/config";

const API_URL = config.apiUrl + config.apiUrlPrefix;

class PositionUnitService {

    getAllPositionUnits() {
        return axios.get(`${API_URL}/position-units`);
    }

    deletePositionUnit(data) {
        return axios({
            method: 'DELETE',
            url: `${API_URL}/position-units/` + data.id,
            data: data
        })
    }

    getTreePositionUnits() {
        return axios.get(`${API_URL}/position-units/tree`);
    }

    createPositionUnit(positionUnitDTO) {
        return axios.post(`${API_URL}/position-units`, positionUnitDTO);
    }

    updatePositionUnit(positionUnitDTO) {
        return axios.put(`${API_URL}/position-units/` + positionUnitDTO.id, positionUnitDTO);
    }
}


export default new PositionUnitService();