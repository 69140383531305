<template>
    <div class="container">
  
        <!--Add Position Unit -->
        <form id="formAddPositionUnit">
            <modal-add-edit-position-unit modalId="modalAddPositionUnit" title="Dodavanje poslovnih jedinica" :listPositionUnits="positionUnits" :positionUnit.sync="newPositionUnit" @confirmed="addPositionUnit" ></modal-add-edit-position-unit>
        </form>

        <!--Edit Position Unit -->
        <form id="formEditPositionUnit">
            <modal-add-edit-position-unit modalId="modalEditPositionUnit" title="Izmena poslovne jedinice" :listPositionUnits="positionUnits" :positionUnit.sync="positionUnitForUpdate" @confirmed="updatePositionUnit" ></modal-add-edit-position-unit>
        </form>

        <modal-confirm @confirm="deleteSelectedPosUnit" inputId="confirmModal" title="Potvrda brisanja poslovne jedinice" message="Da li ste sigurni da želite da obrišete poslovnu jedinicu (Brisanje neće utiacati ako je posao bio zadat na pozicijama)?"/>
        <modal-confirm @confirm="rowDropConfirm" inputId="confirmDDModal" title="Promena grupe posla" message="Da li ste sigurni da želite da prebacite poziciju u drugu nadgrupu?"/>


        <div class="table-title">
            <div class="row">
                <div class="col-sm-5">
                    <h2>Poslovne jedinice</h2>
                </div>
                <div v-if="user.role === 'ADMIN'" class="col-sm-7">
                    <div class="ui-button">
                        <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalAddPositionUnit"><MenuIcon class="icon-2x" /><span class="btn-title">Dodaj novu poslovnu jedinicu</span></button>
                    </div>
                </div>
            </div>
        </div>


        <div class="col-lg-12 control-section">
            <div v-if="positionUnitsTree">
                <ejs-treegrid
                    ref='posUnitGrid'
                    :dataSource='positionUnitsTree'
                    childMapping='children'
                    allowRowDragAndDrop="true"
                    :allowReordering='true'
                    :allowFiltering='true'
                    :allowSorting='true'
                    :filterSettings='filterSettings'
                    :showColumnMenu='true'
                    :allowResizing= 'true'
                    :selectionSettings="selection"
                    :allowGrouping='true'
                    :recordDoubleClick='recordDoubleClick'
                    
            :editSettings='editSettings'
                    :allowPaging='true' 
                    :pageSettings='pageSettings'
                    :toolbar='toolbar'
                    :toolbarClick='clickHandler'
                    v-on:rowDrop="rowDrop" 
                    >
                    <e-columns>
                        <e-column field='code' headerText='Šifra' width='100'></e-column>
                        <e-column field='name' headerText='Naziv' width='188' textAlign='Right'></e-column>
                        <e-column field='norm_unit' headerText='Merna jedinica' width='150' textAlign='Right'></e-column>
                        <e-column field='norm_quantity' headerText='Količina' width='100' textAlign='Right'></e-column>
                        <e-column field='minutes_per_unit' headerText='Minuta po jedinici' width='150'></e-column>
                        <e-column :valueAccessor='baseAccessor' clipMode='EllipsisWithTooltip' field='description' headerText='Opis' width='200' allowFiltering='false' allowSorting='false' filterSettings='false'></e-column>
                    </e-columns>
                </ejs-treegrid>
            </div>
        </div>
    </div>
</template>

<script>
    import PositionUnitService from '@/service/PositionUnitService.js';
    import ModalAddEditPositionUnit from '@/components/modals/ModalAddEditPositionUnit';
    import MenuIcon from 'vue-material-design-icons/Menu.vue';
    import { Modal } from 'bootstrap';
    import {mapState} from 'vuex';
    import { Filter, Sort, Reorder, Resize, RowDD, Toolbar, Page, ColumnMenu, Edit } from "@syncfusion/ej2-vue-treegrid";    import ModalConfirm from '@/components/modals/ModalConfirm.vue';

    export default {       

        components: { MenuIcon, ModalAddEditPositionUnit, ModalConfirm },

        provide: {
            treegrid: [ Filter, Sort, Reorder, Resize, RowDD, Toolbar, Page, ColumnMenu, Edit  ]
        },

        data: function() {
            return {
                positionUnits: [],
                positionUnitsTree: null,
                newPositionUnit: {},
                positionUnitForUpdate: {},

                modalAddPositionUnit: null,
                modalEditPositionUnit: null,
                confirmModal: null,
                confirmDDModal: null,

                filterSettings: { type: 'Excel' },

                selection: { type: 'Multiple' },
                
                pageSettings: { pageCount: 30},
                toolbar: [{ text: 'Dodaj', tooltipText: 'Dodaj', prefixIcon: 'e-add', id: 'addPosUnit' }, { text: 'Izmeni', tooltipText: 'Izmeni', prefixIcon: 'e-edit', id: 'editPosUnit'}, { text: 'Obriši', tooltipText: 'Obriši', prefixIcon: 'e-delete', id: 'deletePosUnit'}],
                editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true },
            }
        },

        computed: {
            ...mapState([
                'user',
            ]),
        },

        created() {
            this.loadData();
        },
        

        mounted() {
            this.modalEditPositionUnit = new Modal(document.getElementById('modalEditPositionUnit'));
            this.modalAddPositionUnit = new Modal(document.getElementById('modalAddPositionUnit'));
            this.confirmModal = new Modal(document.getElementById('confirmModal'));
            this.confirmDDModal = new Modal(document.getElementById('confirmDDModal'));
        },

        methods: {

            loadData() {

                this.loadTreePositionUnits();
                this.loadAllPositionUnits();
            },

            loadAllPositionUnits() {
                PositionUnitService.getAllPositionUnits()
                .then(response => { this.positionUnits = response.data; })
                .catch((error) => { this.toast(error.message, 'error'); });
            },

            loadTreePositionUnits() {
                PositionUnitService.getTreePositionUnits()
                .then(response => { this.positionUnitsTree = response.data; })
                .catch((error) => { this.toast(error.message, 'error'); });
            },

            baseAccessor(field, data) {
                return data[field] ? data[field] : '/';
            },

            validateForm(form) {
                form.classList.add('was-validated');
                return form.checkValidity();
            },

            addPositionUnit() {
                let form = document.getElementById("formAddPositionUnit");
                if (this.validateForm(form) === false)
                    return;

                PositionUnitService.createPositionUnit(this.newPositionUnit)
                .then(response => { 
                    this.loadData();
                    this.modalAddPositionUnit.hide();
                    this.toast("Poslovna jedinica " + response.data.name + " je uspešno kreirana"); 
                })
                .catch((error) => { alert(error.message); });
            },

            openUpdateModal() {
                this.modalEditPositionUnit.show();
            },


            openAddModal() {
                this.modalAddPositionUnit.show();
            },

            updatePositionUnit() {
                let form = document.getElementById("formEditPositionUnit");
                if (this.validateForm(form) === false)
                    return;
                
                PositionUnitService.updatePositionUnit(this.positionUnitForUpdate)
                .then(response => { 
                    this.loadData();
                    this.modalEditPositionUnit.hide();
                    this.toast("Poslovna jedinica " + response.data.name + " je uspešno izmenjena"); 
                }).catch((error) => { this.toast(error.message, 'error'); });
            },

            async updateDragDrop() {
                await PositionUnitService.updatePositionUnit(this.positionUnitForUpdate)
                .then(response => { 
                    this.toast("Poslovna jedinica " + response.data.name + " je uspešno izmenjena"); 
                }).catch((error) => { this.toast(error.message, 'Greska'); });
            },

            toast(message, type) {
                this.$toasted.show(message, { 
                    type: type,
                    theme: "toasted-primary", 
                    position: "top-center", 
                    duration : 3000
               });
            },
            
            async deletePosUnit(data) {
                try {
                    await PositionUnitService.deletePositionUnit(data)
                    this.toast('Uspešno brisanje jedinice.');
                    this.loadData();
                } catch (error) {
                        console.log(error)
                        alert("Neuspesno")
                }
            },

            clickHandler(args) {
                if (args.item.id === 'addPosUnit') {
                    this.openAddModal()
                }

                if (args.item.id === "editPosUnit"){
                    let selected = (this.$refs.posUnitGrid.ej2Instances.getSelectedRecords());
                    if (selected.length > 0) selected = selected[0];
                    else return;

                    this.positionUnitForUpdate = JSON.parse(JSON.stringify(selected));

                    this.openUpdateModal();
                }

                if (args.item.id === "deletePosUnit"){
                    let selected = (this.$refs.posUnitGrid.ej2Instances.getSelectedRecords());
                    if (selected.length > 0) selected = selected[0];
                    else return;
                    
                    this.confirmModal.show();
                }
            },

            deleteSelectedPosUnit(){
                    let selected = (this.$refs.posUnitGrid.ej2Instances.getSelectedRecords());
                    if (selected.length > 0) selected = selected[0];
                    else return;

                    this.deletePosUnit(selected)

            },

            async rowDrop(event) {
                event.cancel = !confirm("Potvrdi pomeranje");
                this.dropEvent = await event;

                // let changed = false;

                // event.data.forEach(item => {
                //     if (item.parent_id != item.parentItem?.id) changed = true;
                // });

                
                // if (changed) this.confirmDDModal.show();
                this.rowDropConfirm();

            },

            async rowDropConfirm(){
                let items = this.dropEvent.data;

                await items.forEach(async item => {
                    console.log(item.parentItem?.id);
                    console.log(item.parent_id);
                    if (item.parent_id == item.parentItem?.id) return

                    item.parent_id = item.parentItem?.id;
                    this.positionUnitForUpdate = item;

                    await this.updateDragDrop();

                });
            },

            recordDoubleClick() {
                console.log(this.$refs.posUnitGrid.ej2Instances.getDataRows());
            }
        }
    };
    
</script>

<style>

    .table-title {
        padding-bottom: 15px;
        background: #299be4;
        color: #fff;
        padding: 16px 30px;
        margin: 0 -25px 10px;
        border-radius: 3px 3px 0 0;
    }

    .table-title h2{
        margin: 5px 0 0;
        font-size: 1.8em;
    }

    .table-title .btn {
        color: #566787;
        float: right;
        font-size: 13px;
        background: #fff;
        border: none;
        min-width: 50px;
        border-radius: 2px;
        border: none;
        outline: none !important;
        margin-left: 10px;
    }

    .table-title .btn:hover, .table-title .btn:focus {
        color: #566787;
        background: #f2f2f2;
    }

    .table-title .btn .btn-title {
        margin-top: 20px;
    }

    .table-wrapper {
        min-width: 1000px;
        background: #fff;
        padding: 20px 25px;
        border-radius: 3px;
        box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    }


    .table td, .table th {
        padding: .75rem;
        vertical-align: top;
        border-top: 1px solid #dee2e6;
    }

    table.table-striped tbody tr:nth-of-type(odd) {
        background-color: #fcfcfc;
    }
    .table-striped tbody tr:nth-of-type(odd) {
        background-color: rgba(0,0,0,.05);
    }

    table.table-striped.table-hover tbody tr:hover {
        background: #f5f5f5;
    }
    table.table thead {
        font-size: 0.8em
    }

</style>