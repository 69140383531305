<template>
    <div class="modal fade" :id="inputId" tabindex="-1" :aria-labelledby="inputId + 'Label'" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"> {{ title }} </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <p> {{ message }} </p>
                </div>

                <div class="modal-footer">
                    <button @click="cancelModal" type="button" class="btn btn-secondary" data-bs-dismiss="modal"> Zatvori </button>
                    <button @click="confirmModal" type="button" class="btn btn-primary" data-bs-dismiss="modal"> Potvrdi </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Modal } from 'bootstrap';

    export default {

        props: {
            inputId: String,
            title: String,
            message: String
        },

        data() {
            return {
                modal: null,
            }
        },

        methods: {
            confirmModal() {
                this.$emit('confirm')
                this.hideModal()
            },

            cancelModal() {
                this.$emit('close')
                this.hideModal()
            },

            showModal() {
                this.modal.show();
            },

            hideModal() {
                this.modal.hide();
            },

            onModalShown() {
                
            },

            setUpListener() {    
                let myModalEl = this.$el
                this.modal = new Modal(myModalEl, {keyboard: false});

                myModalEl.addEventListener('shown.bs.modal', () => {
                    this.onModalShown();
                })
            },
        },

        mounted() {
            this.setUpListener();
        },

    }
</script>

<style>
p {
    font-size: 140%;
}
</style>