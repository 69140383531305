<template>
  <div class="job-assignment">
    <navbar />
    <breadcrumbs></breadcrumbs>
    
    <div class="container">
      <all-position-units/>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import AllPositionUnits from '@/components/AllPositionUnits.vue'
import Footer from '@/components/Footer.vue';

export default {
  components: {
    Navbar,
    AllPositionUnits,
    Footer
  }
}
</script>

<style scoped>
.container {
    min-height: 74vh;
}

</style>
